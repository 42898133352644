import React from "react"
import { Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Navbar from "../components/navBar"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import {isMobile} from 'react-device-detect';
import ReactHtmlParser from 'react-html-parser';
import { GatsbyImage } from "gatsby-plugin-image"

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentLoaded: true,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.setState({ isMobile: isMobile })
  }

  render() {
    const mobile = this.state.isMobile;
    var infoImage = this.props.data.allContentfulPage.edges[0].node;
    var infoItems = this.props.data.allContentfulInfoItem.nodes.map(function(info){
      return(
        <Row className="mx-auto info-item-row">
          <Col md={6} sm={12} lg={6} xl={6} xs={12} className="post-col">
            <h5 className="info-text-title"><b>{info.title}</b></h5>
          </Col>
          <Col md={6} sm={12} lg={6} xl={6} xs={12} className="post-col">
            { ReactHtmlParser (info.childrenContentfulInfoItemDescriptionTextNode[0].childrenMarkdownRemark[0].html) }
          </Col>
        </Row>
      )
    });
    return (
      <Layout pageInfo={{ pageName: "Info" }}>
        <SEO
          title="Info"
          keywords={['Brrrlabs', 'design', 'website', 'product design', 'poster design', 'social media design', 'jasa desain jogja', 'design studio']}
          ogUrl="/info"
          ogImage={infoImage.featuredImage.gatsbyImageData.images.sources[0].srcSet.split(",")[0].split(" ")[0]}
        />
        <Navbar menus={this.props.data.allContentfulMenu.edges} />
        <Row className="mx-auto info-banner">
          <Col md={12} sm={12} lg={12} xl={12} xs={12} className="post-col">
            {
              mobile &&
              <GatsbyImage image={infoImage.featuredImage.gatsbyImageData} className="post-img" style={{height:`80vh`}}/>
            }
            {
              !mobile &&
              <GatsbyImage image={infoImage.featuredImage.gatsbyImageData} className="post-img" style={{height:`auto`}}/>
            }
          </Col>
        </Row>
        {infoItems}
        <Row className="mx-auto" style={{height:`100px`}}>
        </Row>
        <Footer
          isMobile={this.state.isMobile}
          menus={this.props.data.allContentfulMenu.edges}
          siteInfo={this.props.data.allContentfulSiteInfo.nodes[0]}
          socials={this.props.data.allContentfulSocials.nodes}
        />
      </Layout>
    )
  }
}

export default Info

export const Query = graphql`
query InfoPage {
  allContentfulMenu(sort: {fields: order, order: ASC}) {
    edges {
      node {
        name
        link
      }
    }
  }
  allContentfulSiteInfo {
    nodes {
      id
      copyrightText
      addressLink
      address {
        address
      }
      domain
      email
      fullName
      logoLarge {
        gatsbyImageData(formats: WEBP)
      }
      logoSmall {
        gatsbyImageData(formats: WEBP)
      }
      phone
      shortName
    }
  }
  allContentfulSocials {
    nodes {
      link
      name
    }
  }
  allContentfulInfoItem(sort: {fields: order, order: ASC}) {
    nodes {
      title
      description {
        description
      }
      childrenContentfulInfoItemDescriptionTextNode {
        childrenMarkdownRemark {
          html
        }
      }
    }
  }
  allContentfulPage(filter: {name: {eq: "Info"}}) {
    edges {
      node {
        name
        featuredImage {
          gatsbyImageData(placeholder: DOMINANT_COLOR, formats: WEBP, layout: CONSTRAINED)
          title
        }
      }
    }
  }
}
`
